@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

* {
  font-family: "Poppins", sans-serif;
  color: "#333333";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #EBECF0;

}
.login_page {
  padding: 0 6%;
}
.login_wrapper {
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: space-between;
}
.login_url {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 50%;

}
.login_url_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 60%;
  background: #fff;
  padding: 6% 4%;
  border-radius: 10px;
}
.login_url_body span {
  color: #909090;
  font-weight: 500;
}
.login_url_body button {
  padding: 4% 6%;
  background: #628DFB;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid transparent;
  letter-spacing: .5px;
  cursor: pointer;
  transition: all .1s;
}
.login_url_body button:hover {
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
}

.login_image{
  display: flex;
  flex-direction: column;

}
.logo {
  padding: 4% 0 0;
}